<template>

  <section id="agregar-layout">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
            <i class="fa-solid fa-border-all color-secondary pe-2"></i>
            <strong>Layout</strong> Mural Digital
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0">
          <div class="card-body d-flex flex-column mh-700">

            <div class="row mb-3">
              <div class="col-12 col-lg-7 mb-3 mb-lg-0 d-flex align-items-center text-secondary">
                <font-awesome-icon 
                    icon="circle-arrow-down" 
                    class="me-1 color-secondary"
                  />
                Haz clic en los diferentes apartados del layout y añade tu contenido.
              </div>
              <div class="col-12 col-lg-5 text-end">
                <div class="btn-group btn-group-custom btn-group-custom-lg bg-secondary bg-opacity-10">
                  <a 
                    v-for="(color, i) in background_colors"
                    :key="i"
                    @click="selectBackgroudColor(color)"
                    href="javascript:" 
                    class="btn btn-group-custom-link p-2 border-0"
                    >
                    <span 
                      class="btn-group-custom-link-circle"
                      :class="{ 'active': color_selected == color }"
                      :style="{ backgroundColor: color }"
                      >
                      <font-awesome-icon 
                        icon="check"
                        class="btn-group-custom-link-circle-icon"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="layout-monitor mb-3">
                  <div class="layout-monitor-top">
                    <a 
                      href="javascript:" 
                      @click="addLogo"
                      data-bs-toggle="modal" 
                      data-bs-target="#modal-layout-logo"
                      class="layout-monitor-top-logo"
                    >
                      <img 
                        v-if="contentPreview.logo" 
                        :src="contentPreview.logo"
                        class="layout-monitor-top-logo-img"
                      >
                      <p 
                        v-else 
                        class="mb-0 py-2 px-3" 
                        content="Añadir un Logo" 
                        v-tippy="{ arrow: true, placement: 'bottom' }"
                      >
                        <font-awesome-icon 
                            v-if="!contentPreview.logo"
                            icon="plus" 
                            class="color-secondary"
                          />
                      </p>
                    </a>
                    <a 
                      @click="addVideo"
                      href="javascript:"
                      class="layout-monitor-top-video"
                    >
                      <img 
                        v-if="contentPreview.videoImg"
                        class="layout-monitor-top-video-img" 
                        :src="contentPreview.videoImg"
                      />
                      <p
                        v-if="!contentPreview.videoImg"
                        class="layout-monitor-top-video-text mb-0"
                      >
                      <font-awesome-icon 
                        icon="video" 
                        class="fs-1 text-secondary opacity-25"
                      />
                      <br>
                        Añadir videos
                      </p>
                      <span class="layout-monitor-top-edit">
                        <span class="layout-monitor-top-edit-border">
                          <font-awesome-icon 
                            v-if="!contentPreview.videoImg"
                            icon="plus" 
                            class="layout-monitor-top-edit-icon"
                          />
                          <font-awesome-icon 
                            v-if="contentPreview.videoImg"
                            icon="pencil" 
                            class="layout-monitor-top-edit-icon"
                          />
                        </span>
                      </span>
                    </a>
                    <div class="layout-monitor-top-right">
                      <a 
                        @click="addBanner"
                        href="javascript:"
                        class="layout-monitor-top-right-banner"
                      >
                        <img 
                          v-if="contentPreview.bannerImg"
                          class="layout-monitor-top-right-banner-img"
                          :src="contentPreview.bannerImg"
                        />
                        <p
                          v-if="!contentPreview.bannerImg"
                          class="layout-monitor-top-right-banner-text mb-0"
                        >
                        <font-awesome-icon 
                          icon="image" 
                          class="fs-1 text-secondary opacity-25"
                        /><br>
                          Añadir banners
                        </p>
                        <span class="layout-monitor-top-edit">
                          <span class="layout-monitor-top-edit-border">
                            <font-awesome-icon 
                              v-if="!contentPreview.bannerImg"
                              icon="plus" 
                              class="layout-monitor-top-edit-icon"
                            />
                            <font-awesome-icon 
                              v-if="contentPreview.bannerImg"
                              icon="pencil" 
                              class="layout-monitor-top-edit-icon"
                            />
                          </span>
                        </span>
                      </a>
                      <a 
                        href="javascript:"
                        class="layout-monitor-top-right-hour"
                      >
                        <span v-if="date.getHours() < 10">
                          0{{ date.getHours() }}:
                        </span>
                        <span v-else>
                          {{ date.getHours() }}:
                        </span>
                        <span v-if="date.getMinutes() < 10">
                          0{{ date.getMinutes() }}:
                        </span>
                        <span v-else>
                          {{ date.getMinutes() }}:
                        </span>
                        <span v-if="date.getSeconds() < 10">
                          0{{ date.getSeconds() }}
                        </span>
                        <span v-else>
                          {{ date.getSeconds() }}
                        </span>
                      </a>
                    </div> 
                  </div>
                  <div class="layout-monitor-bottom">
                    <a
                        v-for="(item, index) in widget_data"
                        :key="index"
                        href="javascript:"
                        @click="addWidget(index)"
                        data-bs-toggle="modal" º
                        data-bs-target="#modal-layout-widget"
                        class="layout-monitor-bottom-widget"
                        :class="{'layout-monitor-bottom-widget-active': color_selected != ''}"
                        :style="colorSelected"
                      >
                        <p
                          v-if="!contentPreview.widgets || !contentPreview.widgets[index]"
                          class="mb-0 d-flex flex-column flex-lg-row align-items-center text-secondary"
                          :class="{ 'text-white': color_selected != '' }"
                        >
                          <font-awesome-icon icon="plus" class="color-secondary me-1"/>
                          {{ item.text }}
                        </p>
      
                        <div 
                          v-if="contentPreview.widgets && contentPreview.widgets[index]" 
                          class="d-flex flex-column flex-lg-row align-items-center"
                        >
                          <div class="flex-shrink-0">
                            <img 
                              class="layout-monitor-bottom-widget-img"
                              :src="contentPreview.widgets[index].imagen_widget"/>
                          </div>
                          <div class="flex-grow-1 ms-lg-3">
                            <span 
                              class="layout-monitor-bottom-widget-title"
                              :class="{ 'text-white': color_selected != '' }"
                            >
                              {{ contentPreview.widgets[index].nombre_widget }}
                            </span>
                          </div>
                        </div>  
                      </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-end">
                  <button
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    @click="backToChannel"
                  >
                    Volver
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click="addToChannel" 
                    :disabled="!isLayoutValidForBack"
                  >
                    Añadir
                  </button>
                </div> 
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-layout-logo" 
      tabindex="-1" 
    >
      <div class="modal-dialog">
          <AgregarLogoModal
            v-show="logo_modal == true" 
            @closeLogo="closeLogo"
            :saved_logo_src="saved_logo_src"
          />
      </div>
    </div>

    <div 
      class="modal modal-custom fade" 
      id="modal-layout-widget" 
      tabindex="-1" 
    >
      <div class="modal-dialog modal-xl">
        <AgregarWidget 
          v-if="widget_modal == true" 
          @closeWidget="closeWidget" 
          :rank="selected_widget_rank" 
          :widgets="widgets" 
        />
      </div>
    </div>

<!--     <div 
      v-if="widget_modal == true" 
      class="add-contenido__open-modal"
    >
      <AgregarWidget 
        @closeWidget="closeWidget" 
        :rank="selected_widget_rank" 
        :widgets="widgets" 
      />
    </div> -->
    <Spinner v-show="show_spinner"/>

<!--     <section class="add-pantalla">
      <div class="add-pantalla__container">
        <div class="add-pantalla__wrapper">
          <div class="add-pantalla__top-container">
            <div class="add-pantalla__top-wrapper">
              <h2 class="add-pantalla__title">Layout Mural Digital</h2>
              <p class="add-pantalla__subtitle">
                Haz clic en los diferentes apartados del layout y añade tu
                contenido.
              </p>
            </div>
            <div class="add-contenido__layout-container">
              <div class="add-contenido__layout-wrapper">
                <div @click="addLogo" class="add-contenido__logo-wrapper">
                  <img v-if="contentPreview.logo" :src="contentPreview.logo" alt="" style="width:100%;height:100%;object-fit:fill;">
                  <p v-else class="mb-0" content="Cargar imagen" v-tippy="{ arrow: true, placement: 'bottom' }">
                    Añadir logo
                  </p>
                </div>
                <div class="add-contenido__layout-top">
                  <div
                    v-if="!contentPreview.videoImg"
                    @click="addVideo"
                    class="add-contenido__top-left"
                  >
                    <div class="add-contenido__top-img">
                      <img src="@/assets/img/tv/play_bw.svg" alt="" />
                      <p class="add-contenido__text mt-3">
                        Añade <br />
                        tus videos
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="contentPreview.videoImg"
                    @click="addVideo"
                    class="add-contenido__top-left"
                  >
                    <div class="add-contenido__video-selected-container">
                      <img class="add-contenido__video-selected-img" :src="contentPreview.videoImg" alt=""/>
                      <img src="@/assets/img/tv/play_bw.svg" style="position:absolute" alt="" />
  
                      <div class="add-contenido__banner-edit">
                        <div class="add-contenido__banner-edit--white">
                          <img class="add-contenido__banner-edit-img" src="@/assets/img/Editar.svg" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="add-contenido__top-right"
                    :class="{
                      'add-contenido__top-right--grey': color_selected == '',
                      'add-contenido__top-right--white': color_selected != '',
                    }"
                  >
                    <div
                      v-if="!contentPreview.bannerImg"
                      @click="addBanner"
                      class="add-contenido__top-banner"
                    >
                      <img src="@/assets/img/tv/añade_banners.svg" alt="" />
                      <p class="add-contenido__text mt-4">
                        Añade <br />
                        tus banners
                      </p>
                    </div>
                    <div
                      v-if="contentPreview.bannerImg"
                      @click="addBanner"
                      class="add-contenido__top-banner"
                    >
                      <div class="add-contenido__container-banner-middle">
                        <img 
                          class="add-contenido__banner-img"
                          :src="contentPreview.bannerImg"
                          alt=""
                        />
                        <div class="add-contenido__banner-edit">
                          <div class="add-contenido__banner-edit--white">
                            <img
                              class="add-contenido__banner-edit-img"
                              src="@/assets/img/Editar.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="add-contenido__top-time"
                      :class="{
                        'add-contenido__top-time--grey': color_selected == '',
                        'add-contenido__top-time--white': color_selected != '',
                      }"
                      :style="{ backgroundColor: color_selected }"
                    >
                      <p
                        class="add-contenido__text"
                        :class="{ 'text-white': color_selected != '' }"
                      >
                        06:00
                      </p>
                    </div>
                  </div>
                </div>
                <div class="add-contenido__layout-bottom">
                  <div
                    v-for="(item, index) in widget_data"
                    :key="index"
                    @click="addWidget(index)"
                    class="add-contenido__bottom-widget"
                    :class="{
                      'add-contenido__bottom-widget--grey': color_selected == '',
                      'add-contenido__bottom-widget--white': color_selected != '',
                    }"
                    :style="{ backgroundColor: color_selected }"
                  >
                    <p
                      v-if="!contentPreview.widgets || !contentPreview.widgets[index]"
                      class="add-contenido__text"
                      :class="{ 'text-white': color_selected != '' }"
                    >
                      {{ item.text }}
                    </p>
  
                    <div v-if="contentPreview.widgets && contentPreview.widgets[index]" class="add-widget__widget-container-layout">
                      <div class="add-widget__widget-img-layout">
                        <img 
                          class="add-widget__img" 
                          :src="contentPreview.widgets[index].imagen_widget" 
                          alt="" 
                        />
                      </div>
                      <p 
                        class="add-widget__widget-text-layout"
                        :class="{ 'text-white': color_selected != '' }"
                      >
                        {{ contentPreview.widgets[index].nombre_widget }}
                      </p>
                    </div>
    
                  </div>
                </div>
              </div>
              <div class="add-contenido__color-container">
                <p class="add-contenido__color-text">
                  Colores de fondo disponibles
                </p>
                <div class="add-contenido__color-wrapper">
                  <button
                    v-for="(color, i) in background_colors"
                    :key="i"
                    @click="selectBackgroudColor(color)"
                    class="add-contenido__color-white"
                    :class="{ 'add-contenido__color-selected': color_selected == color }"
  
                  >
                    <div
                      class="add-contenido__color-circle"
                      :style="{ backgroundColor: color }"
                    ></div>
                    <img
                      class="add-contenido__color-img"
                      src="@/assets/img/check_white.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="add-contenido__buttons-container">
            <button @click="backToChannel" class="add-contenido__back-button">
              Volver
            </button>
            <button 
              @click="addToChannel" 
              class="add-contenido__add-button ms-3"
              :class="{
                'add-pantalla__add-active': isLayoutValidForBack,
                'add-pantalla__add-disable': !isLayoutValidForBack,
              }"
              :disabled="!isLayoutValidForBack"
            >
              Añadir
            </button>
          </div>
        </div>
      </div>
      <div v-show="logo_modal == true" class="add-contenido__open-modal">
        <div class=""></div>
        <AgregarLogoModal @closeLogo="closeLogo" :saved_logo_src="saved_logo_src"/>
      </div>
      <div v-if="widget_modal == true" class="add-contenido__open-modal">
        <AgregarWidget @closeWidget="closeWidget" :rank="selected_widget_rank" :widgets="widgets" />
      </div>
      <Spinner v-show="show_spinner" />
    </section> -->
  
  </section>

</template>

<script>
import { 
  GET_ALL_WIDGETS,
} from "@/apollo/queries";
import { mapState, mapActions, mapGetters } from "vuex";
import AgregarLogoModal from "./AgregarLogoModal.vue";
import AgregarWidget from "./AgregarWidget.vue";
import Spinner from "../Spinner.vue";
export default {
  components: {
    AgregarLogoModal,
    AgregarWidget,
    Spinner,
  },
  data() {
    return {
      show_spinner: false,
      logo_modal: false,
      widget_modal: false,
      background_colors: [
        "#0072f7",
        "#357BF3",
        "#05CFC1",
        "#23BE61",
        "#F26F28",
        "#E64141",
        "#EC206B",
      ],
      widgets: [],
      widget_selected: false,
      widget_data: [
        { text: "Widget" },
        { text: "Widget" },
        { text: "Widget" },
        { text: "Widget" },
      ],
      color_selected: "",
      selected_widget_rank: "",
      saved_logo_src: "",
      unsubscribe: false,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      contentPreview: {},

      //FF
      date: new Date()
    };
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),
    ...mapGetters("tvCorporativaModule", ["isLayoutValidForBack"]),

    //FF
    colorSelected: function() {
      if(this.color_selected !== '') {
        return {
          '--color': this.color_selected,
          '--color-hover': `${this.color_selected}E6`
        }
      }
      else {
        return ''
      }
    }
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      this.contentPreview = this.getContentPreview(state.tvCorporativaModule.channelLayout)
      localStorage.setItem('channelLayout', JSON.stringify(state.tvCorporativaModule.channelLayout));
    });
  },

  async mounted() {
    this.getAllWidgets();

    if (this.channelLayout.id_canal != this.$route.params.id) {
      this.resetChannelContent();
    }
    if (this.$route.name.includes("edit")) {
      await this.getSavedChannelContent();
    } else {
      this.setChannelLayoutId(this.$route.params.id);
    }

    if (this.channelLayout.color) {
      this.color_selected = this.channelLayout.color;
    }

    this.contentPreview = this.getContentPreview(this.channelLayout);
  },

  methods: {
    ...mapActions("tvCorporativaModule", 
      ["setChannelLayoutColor", "setChannelLayoutId", "setChannelLayout", "resetChannelContent", "getCanal"]),

    getContentPreview(channelState) {
      return {
        logo: channelState.logo.src ? channelState.logo.base64 : channelState.url_logo,
        videoImg: channelState.videos.length ? channelState.videos[0].url_imagen : null,
        bannerImg: channelState.banners.length ? channelState.banners[0].url_archivo : null,
        widgets: [...Array(4).keys()].map(i => channelState.widgets[i] ?? null),
      };
    },

    async getSavedChannelContent() {
      this.show_spinner = true;
      if (this.channelLayout.id_canal != this.$route.params.id) {        
        const payload = {
          idCanal: this.$route.params.id,
          idEmpresa: this.id_empresa,
        };
        await this.getCanal(payload);
      }

      this.color_selected = this.channelLayout.color;
      this.saved_logo_src = this.channelLayout.logo.base64 ?? this.channelLayout.url_logo;
      this.show_spinner = false;
    },

    widgetListIncomplete() {
      const widgets_num = Object.keys(this.channelLayout.widgets).length;
      return widgets_num < 4; 
    },
    getAllWidgets() {
      this.show_spinner = true;
      this.$apollo.query({
        query: GET_ALL_WIDGETS,
        variables: {
          idEmpresa: this.id_empresa,
        }
      }).then((res) => {
        this.widgets = res.data.GetAllWidgets;
        this.show_spinner = false;
      }).catch(() => {
        this.widgets = [];
        this.show_spinner = false;
      });
    },

    addLogo() {
      this.logo_modal = true;
    },
    closeLogo() {
      this.logo_modal = false;
    },
    addVideo() {
      if (this.$route.name.includes("edit")) {
        this.$router.push({
          name: "CanalCorporativo-editVideos",
          params: {
            id: this.$route.params.id,
          },
        });        
      } else {
        this.$router.push({
          name: "CanalCorporativo-listadoVideos",
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    addBanner() {
      this.$router.push({
        name: "CanalCorporativo-listadoBanners",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    addWidget(index) {
      this.selected_widget_rank = String(index);
      this.widget_modal = true;
    },
    closeWidget() {
      this.widget_modal = false;
    },
    selectBackgroudColor(color) {
      if (color === this.color_selected) {
        this.color_selected = "";
      } else {
        this.color_selected = color;
      }
      this.setChannelLayoutColor(this.color_selected);
    },
    backToChannel() {
      this.$router.push({
        name: "CanalCorporativo-canales",
      });
    },

    addToChannel() {
      if (this.widgetListIncomplete()) {
        this.$toast.open({
          message: "Debes seleccionar los 4 widgets",
            type: "warning",
            duration: 6000,
            position: "top-right",      
        });
        return;
      }
      this.show_spinner = true;
      const data = {
        id_usuario: this.$ls.get("user").id_usuario,
        id_empresa: this.id_empresa,
      };
      if (this.$route.name.includes("edit")) {
        Object.assign(data, { id_lista: this.channelLayout.id_lista });
      }
      this.setChannelLayout(data).then(res => {
        if (!res) {
          this.show_spinner = false;
          this.$toast.open({
            message: "Falla al momento de agregar contenido al canal",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
        } else {
          this.show_spinner = false;
          this.$toast.open({
            message: "Tu contenido ha sido agregado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.resetChannelContent();
          this.$router.push({ name: "CanalCorporativo-canales" });
        }
      });
      if (this.unsubscribe != false) {
        // Desuscribe del subscribe a los cambios en la store
        this.unsubscribe();
      }
    },
  },
};
</script>

<style scope lang="scss">
  .layout-monitor-bottom-widget-active {
    background-color: var(--color);
  }

  .layout-monitor-bottom-widget-active:hover {
    background-color: var(--color-hover);
  }
</style>