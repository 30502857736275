<template>

  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">
        <font-awesome-icon 
          icon="border-all" 
          class="color-secondary me-1"
        />
        <strong>Layout</strong> Widget
      </h5>
      <button 
        type="button" 
        class="modal-custom-close"
        data-bs-dismiss="modal" 
        aria-label="Close">
        <font-awesome-icon icon="xmark"/>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-12 col-lg-6">
          <h5 class="font-main text-secondary">Selecciona un widget</h5>
          <div class="list-group">
            <a 
              @click="selectWidget(w)"
              v-for="(w, i) in widgets"
              :key="i"
              href="javascript:" 
              class="list-group-item list-group-item-action"
              :class="w.nombre_widget === selected_widget.nombre_widget ? 'active' : '' "
            >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img 
                  class="img-obj img-obj-60" 
                  :src="w.imagen_widget"
                >
              </div>
              <div class="flex-grow-1 d-flex justify-content-between ms-3">
                <h5 class="mb-0">{{ w.nombre_widget }}</h5>
                <font-awesome-icon 
                  icon="angle-right"
                  class="fs-3 d-none d-lg-inline"
                  :class="w.nombre_widget === selected_widget.nombre_widget ? 'text-white' : 'text-secondary opacity-50' "
                />
              </div>
            </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-3 mt-lg-0">
          <h5 class="font-main text-secondary">Widget seleccionado</h5>
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img 
                    class="img-obj img-obj-60" 
                    :src="selected_widget.imagen_widget"
                  >
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="mb-0">{{ selected_widget.nombre_widget }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h6 class="font-main text-secondary">
                <font-awesome-icon 
                  icon="gear" 
                  class="color-secondary"
                />
                Configurar widget
              </h6>

              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h6 class="font-main border-bottom text-secondary pb-1 mb-3">
                        <font-awesome-icon 
                          :icon="['fa-regular', 'clock']" 
                        />
                        Tiempo en pantalla
                      </h6>
                    </div>
                  </div>
                  <div 
                    style="height: 48px"
                    class="row">
                    <div class="col-12 col-lg-8 d-flex align-items-center">
                      <div class="form-check form-switch form-switch-custom">
                        <input 
                          class="form-check-input"
                          type="checkbox" 
                          id="switch-time"
                          v-model="time_selected" 
                        >
                        <label
                          class="form-check-label link-cursor" 
                          for="switch-time"
                        >
                          Activar una duración
                        </label>
                      </div>
                    </div>
                    <div 
                      v-if="time_selected"
                      class="col-12 col-lg-4 d-flex align-items-center justify-content-end">
                      <button 
                        @click="removeTime()"
                        class="btn btn-custom-color-white rounded-circle border"
                        type="button"
                      >
                        <font-awesome-icon icon="minus"/>
                      </button>
                      <span class="border rounded-2 py-1 px-2 mx-2 mw-100 text-center">{{ count }} s</span>
                      <button 
                        @click="addTime()"
                        class="btn btn-custom-color-white rounded-circle border"
                        type="button"
                      >
                        <font-awesome-icon icon="plus"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

<!--       <section class="add-widget__container">
        <div class="add-widget__wrapper">
          <div class="add-widget__top">
            <div class="add-widget__top-left">
              <h2 class="add-widget__left-title">Seleccionar el Widget</h2>
              <div class="add-widget__left-wrapper">
                <div
                  @click="selectWidget(w)"
                  v-for="(w, i) in widgets"
                  :key="i"
                  class="add-widget__widget-container"
                >
                  <div class="add-widget__widget-img">
                    <img class="add-widget__img" :src="w.imagen_widget" alt="" />
                  </div>
                  <p class="add-widget__widget-text">{{ w.nombre_widget }}</p>
                </div>
              </div>
            </div>
            <div
              :class="{
                'add-widget__top-right': is_widget_selected == false,
                'add-widget__top-right--selected': is_widget_selected == true,
              }"
            >
              <h2 class="add-widget__right-title">Widget seleccionado</h2>
              <div class="add-widget__right-container">
                <div
                  v-show="is_widget_selected == false"
                  class="add-widget__right-wrapper"
                ></div>
                <div
                  v-show="is_widget_selected == true"
                  class="add-widget__widget-container"
                >
                  <div class="add-widget__widget-img">
                    <img class="add-widget__img" :src="selected_widget.imagen_widget" alt="" />
                  </div>
                  <p class="add-widget__widget-text">{{ selected_widget.nombre_widget }}</p>
                </div>
                <p v-show="is_widget_selected == true" class="add-widget__text-widget">
                  Información sobre el widget
                </p>
              </div>
              <div
                v-show="is_widget_selected == true"
                class="add-widget__time-container"
              >
                <h3 class="add-widget__time-title">
                  Selecciona el tiempo en pantalla
                </h3>
                <div class="add-widget__time-content">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled"
                      ref="flexRadioDisabled"
                      value="true"
                      v-model="time_selected"
                    />
                  </div>
                  <label for="flexRadioDisabled" class="add-widget__checkbox-label">
                    Establecer duración</label
                  >
                  <div class="add-widget__buttons-wrapper">
                    <button
                      @click="removeTime"
                      :disabled="time_selected == false"
                      class="add-widget__remove-time"
                    >
                      -
                    </button>
                    <div class="add-widget__time">{{ count }} s</div>
                    <button
                      @click="addTime"
                      :disabled="time_selected == false"
                      class="add-widget__add-time"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->

    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        ref="closeModal"
        class="btn btn-custom-color-white rounded-pill mw-100" 
        data-bs-dismiss="modal">
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>
      <button 
        @click="addWidgetToChannel()"
        type="button" 
        class="btn btn-custom-color-blue rounded-pill mw-100">
        Añadir
      </button>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    rank: String,
    widgets: Array,
  },

  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      time_selected: false,
      is_widget_selected: false,
      selected_widget: {},
      count: 4,
    };
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),
  },

  mounted() {
    this.setWidgetFromState();
  },

  methods: {
    ...mapActions("tvCorporativaModule", ["setChannelLayoutWidgets", "getChannelStateFromLS"]),

    setWidgetFromState() {
      this.getChannelStateFromLS();
      if (Object.keys(this.channelLayout.widgets).length != 0) {
        if (this.channelLayout.widgets[this.rank]) {
          this.selectWidget(this.channelLayout.widgets[this.rank]);
          this.count = this.channelLayout.widgets[this.rank].tiempo_ejecucion;
        }
      }
    },

    addWidgetToChannel() {
      const payload = {
        widget: this.selected_widget,
        rank: this.rank,
        tiempo_ejecucion: this.count
      };
      this.setChannelLayoutWidgets(payload);
      this.cleanSelectedWidget();
      this.$emit("closeWidget");
      //FF
      this.$refs.closeModal.click();
    },

    cleanSelectedWidget() {
      this.time_selected = false;
      this.is_widget_selected = false;
      this.selected_widget = {};
      this.count = 4;
    }, 

    closeWidget() {
      this.cleanSelectedWidget();
      this.$emit("closeWidget");
    },

    selectWidget(widget) {
      this.is_widget_selected = true;
      this.selected_widget = widget;
    },

    addTime() {
      this.count++;
    },

    removeTime() {
      if (this.count > 0) {
        this.count--;
      }
    },
  },
};
</script>

<style>
</style>