<template>

  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">
        <font-awesome-icon 
          icon="border-all" 
          class="color-secondary me-1"
        />
        <strong>Layout</strong> logotipo
      </h5>
      <button 
        type="button" 
        class="modal-custom-close"
        data-bs-dismiss="modal" 
        aria-label="Close">
        <font-awesome-icon icon="xmark"/>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-info">
            <font-awesome-icon icon="circle-info" class="me-1"/>
            <small>Formato PNG | Dimensiones 500x500 px | Tamaño máximo 100MB</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <UploadImg 
            @sendImg="handleLogoSelect"
            v-if="img_preview_src === ''"
            :viewport="viewport"
          />
          <div 
              v-if="img_preview_src !== ''"
              class="upload-image"
            >
              <div class="upload-image-preview">
                <button 
                  @click="deletePreview()"
                    class="upload-image-preview-remove"   
                >
                    <font-awesome-icon icon="times"/>
                </button>
                <img 
                    class="upload-image-preview-img"
                    :src="img_preview_src"/>
              </div>
            </div>
        </div>
      </div>

<!--       <div class="add-logo__container">
        <div class="add-logo__top-container">
          <font-awesome-icon
            @click="closeLogo"
            class="add-logo__close-icon"
            icon="times"
          ></font-awesome-icon>
          <p class="add-logo__title">
            Formato PNG - Dimensiones 500x500 px - Tamaño máximo 100 MB
          </p>
        </div>
        <div class="add-logo__middle-container">
          <UploadImg @sendImg="handleLogoSelect" v-if="img_preview_src == ''" :viewport="viewport" />
          <div class="img-preview" v-if="img_preview_src !== ''">
            <div class="delete-preview" @click="deletePreview()">
              <button>
                <font-awesome-icon icon="times"></font-awesome-icon>
              </button>
            </div>
            <div class="img-preview-container">
              <img :src="img_preview_src" alt="" />
            </div>
          </div>
          <p class="add-logo__legend-text">Vista previa</p>
        </div>
        <div class="add-logo__buttons-container">
          <button @click="closeLogo" class="add-logo__button-cancel">Cancelar</button>
          <button @click="setLogo" class="add-logo__button-add">Guardar</button>
        </div>
      </div> -->


    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        ref="closeModal"
        class="btn btn-custom-color-white rounded-pill mw-100" 
        data-bs-dismiss="modal">
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>
      <button 
        @click="setLogo()"
        type="button" 
        class="btn btn-custom-color-blue rounded-pill mw-100">
        Guardar
      </button>
    </div>
  </div>
  
</template>

<script>
import { mapActions, mapState } from "vuex";
import UploadImg from "../UploadImg.vue";

export default {
  components: {
    UploadImg
  },

  props: {
    saved_logo_src: String,
  },

  data() {
    return {
      logo_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      img_preview_src: "",
      viewport: { width: 300, height: 300 }
    }
  },

  watch: {
    saved_logo_src() {
      if (this.$route.name.includes("edit")) {
        this.img_preview_src = this.saved_logo_src;
      } else {
        this.img_preview_src = this.logo_img.base64;
      }
    }
  },

  mounted() {
    this.img_preview_src = this.channelLayout.logo.base64 ?? this.logo_img.base64;
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),
  },

  methods: {
    ...mapActions("tvCorporativaModule", ["setChannelLayoutLogo", "deleteChannelLayoutLogo"]),

    setLogo() {
      this.setChannelLayoutLogo(this.logo_img);
      this.$emit("closeLogo");
      this.$refs.closeModal.click();
    },

    closeLogo() {
      this.$emit("closeLogo");
    },
    handleLogoSelect(img) {
      this.logo_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
      };
      // TODO: controlar formato, dimensiones y tamaño
    },
    deletePreview() {
      this.deleteChannelLayoutLogo();
      this.logo_img = {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      };
      this.img_preview_src = this.logo_img.base64;
    },
  },
};
</script>

<style lang="scss" scoped>
*::v-deep .upload-img-container .preview-img {
  position: relative;
  width: 100%;
  height: 100%;
}

*::v-deep .upload-img-container {
  border: 1px dashed #0072f7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  cursor: pointer;
  padding: 0 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: auto;
}


</style>